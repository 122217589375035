<template>
  <div class="list-page">
    <list-filter :filterForm="filterForm" :uploadFilter="ok" :ids="selectArr" :page="pageData.current_page" />
    </list-tabs>
    <div class="list-content" style="margin-top: 20px;" v-loading="loading">
      <el-table :data="tbody" class="thead-light" stripe style="width: 100%"
        @selection-change="handleSelectionChange" @sort-change="sortChange">
        <!-- 勾选-->
        <el-table-column fixed type="selection" width="55"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="160" fixed="right">
          <template slot-scope="scope">
            <el-button :disabled="disableCheckBtn(scope.row.data_res)" type="text"
              @click="checkRow(scope.row.data_res)">查看</el-button>
            <el-button size="small" @click="feedbackRow(scope.row)" :disabled="scope.row.status === 1"
              type="text">反馈处理结果</el-button>
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
            :min-width="th.minWidth || ''" :key="index" :sortable="th.sortable">
            <template slot-scope="scope">
              <!-- 举报内容 -->
              <div v-if="th.prop == 'data_res'">
                {{ scope.row[th.prop].content || '【资源】' }}
              </div>
              <!-- 普通 -->
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />

    <!-- 内容详情弹窗 -->
    <CheckContentDialog :contentData="checkData" v-model="showContentDialog" />

    <!-- 反馈处理弹窗 -->
    <ReportHandleDialog v-model="showReportHandleDialog" :id="current_id" :updateList="refreshList" />

    <VideoDialog />
  </div>
</template>

<script>
import listFilter from "../components/ListFilter";
import Pagination from "@/base/components/Default/Pagination";
import CheckContentDialog from "../components/CheckContentDialog";
import VideoDialog from "@/base/components/Preview/VideoViewer";
import ReportHandleDialog from "../components/ReportHandleDialog";

import { reportList } from "../api/list.js";

export default {
  data() {
    return {
      loading: false, //加载
      thead: [
        { label: "举报内容", prop: "data_res", minWidth: 200 },
        { label: "类型", prop: "data_type_text", minWidth: 110 },
        {
          label: "举报原因",
          prop: "reason",
          minWidth: 150,
        },
        {
          label: "举报人",
          prop: "user_nickname",
          minWidth: 110,
        },
        {
          label: "举报时间",
          prop: "create_time",
          minWidth: 160,
          sortable: true,
        },
        {
          label: "被举报人",
          prop: "to_user_nickname",
          minWidth: 110,
        },
        {
          label: "状态",
          prop: "status_text",
          minWidth: 90,
        },
      ],
      //筛选对象
      filterForm: {
        create_start_time: -1,
        create_end_time: -1,
        keyword: "",
        nickname: "",
        status: -1,
        data_type: "",
        page_size: 15, //每页多少条数据
      },
      pageData: {
        page_size: 15,
      },
      tbody: [],
      selectArr: [], //勾选的数组id
      current_id: "", // 当前反馈的内容id
      checkData: {},
      showContentDialog: false,
      showReportHandleDialog: false,
    };
  },
  methods: {
    // 获取品牌列表
    getReportList(pageData) {
      this.tbody = [];
      this.pageData = {};
      this.loading = true;
      reportList(pageData)
        .then((res) => {
          const { data } = res;
          this.tbody = data.data;
          this.pageData = data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id);
        });
      }
    },
    // 判断内容是否可以查看
    disableCheckBtn(item) {
      return !(
        (item.video && item.video.length) ||
        (item.images && item.images.length) ||
        (item.medias && item.medias.length)
      );
    },
    // 点击查看内容详情
    checkRow(row) {
      this.checkData = row;
      this.showContentDialog = true;
    },
    // 点击举报反馈
    feedbackRow(row) {
      this.current_id = row.id;
      this.showReportHandleDialog = true;
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getReportList(this.filterForm);
    },
    // 筛选回调
    ok(e) {
      const pageData = {};
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.getReportList({ ...this.filterForm, ...pageData });
    },
    // 刷新列表
    refreshList() {
      this.getReportList({
        page: this.pageData.current_page,
        ...this.filterForm,
      });
    },
    // 分页查询
    changPage(e) {
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
      this.getReportList(pageData);
    },
  },
  created() {
    // this.getPidList(); //获取上级组织列表
    this.getReportList(this.pageData); //获取列表数据
  },
  components: {
    listFilter,
    Pagination,
    CheckContentDialog,
    VideoDialog,
    ReportHandleDialog,
  },
};
</script>

<style lang="scss" scoped>
.container {
  .body {
    .err-box {
      ::v-deep.el-input__inner {
        color: red;
      }
    }
  }
}
</style>

<!--
 * @Author: dongjia
 * @Date: 2022-04-12 11:04:50
 * @LastEditTime: 2022-04-12 15:44:05
 * @LastEditors: aleaner
 * @Description: 
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\report-admin\components\ListFilter.vue
 * 怎么可能会有bug！！！
-->
<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :toggleCollapseEnable="true" :onExport="onExport">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium"
      class="filter-from">

      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="举报内容：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入举报内容"></el-input>
        </el-form-item>
        <el-form-item label="类型：" prop="data_type">
          <el-select v-model="form.data_type" placeholder="请选择类型">
            <el-option v-for="item in categoryOptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态：" prop="status">
          <el-select v-model="form.status" placeholder="请选择状态">
            <el-option v-for="item in statusOptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="举报人/被举报人：" label-width="120px" prop="nickname">
          <el-input v-model="form.nickname" placeholder="请输入"></el-input>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker label="举报时间：" start-prop="create_start_time"
          :start-time.sync="form.create_start_time" end-prop="create_end_time"
          :end-time.sync="form.create_end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
    ids: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      rules: {},
      categoryOptions: [
        { name: "全部", id: "" },
        { name: "资源圈", id: "cyc_circle" },
        { name: "社区", id: "circle" },
        { name: "企业", id: "company" },
        { name: "评论", id: "comment" },
        { name: "会员", id: "user" },
      ],
      statusOptions: [
        { name: "全部", id: -1 },
        { name: "待处理", id: 0 },
        { name: "已处理", id: 1 },
      ],
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    // 导出列表
    onExport() {
      // const postData = { ...this.form, export_ids: this.ids, page: this.page };
      // documentBrandList(postData).catch((err) => {});
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  created() {},
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>
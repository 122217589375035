<!--
 * @Author: dongjia
 * @Date: 2022-04-12 16:11:35
 * @LastEditTime: 2022-04-13 13:51:30
 * @LastEditors: aleaner
 * @Description: 查看内容详情弹窗
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\report-admin\components\CheckContentDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="查看" :visible.sync="openDialog" width="450px" class="dialog-vertical">
    <div class="content">
      <div class="content-text" v-if="contentData.content">{{contentData.content}}</div>
      <div class="content-media" :style="{
        marginTop: contentData.content?'16px':0
      }" v-if="mediaList.length">
        <div class="media-list" :class="isImages?'imgs':'video'">
          <div class="list-item" v-for="(item, index) in mediaList" :key="index">
            <el-image class="item-img cursor-pointer" :src="fixImageUrl(isImages?item.url:item.thumbnail)"
              fit="cover" @click="$previewImage([isImages?item.url:item.thumbnail])">
            </el-image>
            <div class="video-mask" v-if="!isImages">
              <img class="play-icon cursor-pointer" src="@/base/assets/images/media/video-play@2x.png"
                @click="handlePlay(item)" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer">
      <el-button size="small" @click="openDialog = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    contentData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    // 视频播放
    handlePlay(data) {
      this.$store.commit("media/setIsOpenMediaVideoDialog", true);
      this.$store.commit("media/setDialogDetail", data);
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
    mediaList() {
      return this.contentData.video && this.contentData.video.length
        ? this.contentData.video
        : this.contentData.images && this.contentData.images.length
        ? this.contentData.images
        : this.contentData.medias && this.contentData.medias.length
        ? this.contentData.medias
        : [];
    },
    isImages() {
      return this.mediaList[0] && this.mediaList[0].type == "image";
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  .content-text {
  }
  .content-media {
    .media-list {
      &.imgs {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        row-gap: 12px;
        column-gap: 12px;
        .list-item {
          @include fixed-ratio-box(100%);
          .item-img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 4px;
          }
        }
      }
      &.video {
        @include fixed-ratio-box(56%);
        .video-mask,
        .item-img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 4px;
        }
        .video-mask {
          z-index: 2;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          .play-icon {
            width: 46px;
            height: 46px;
          }
        }
      }
    }
  }
}
</style>
